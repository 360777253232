import request from '@/utils/request'

/**
 * 获取专属服务经理是否开通
 * */
export function getServiceStatus(data) {
  return request({
    url: '/work/shop/isOpenServerManager',
    method: 'post',
    data,
  })
}

/**
 * 开通专属服务经理
 * */
export function setServiceStatus(data) {
  return request({
    url: '/work/shop/openServerManager',
    method: 'post',
    data,
  })
}
/**
 *
 *开通专属服务金额
 */
export function getpay(data) {
  return request({
    url: '/work/shop/open_server_manager/price',
    method: 'get',
    data,
  })
}
/**
 *
 *开通专属服务
 */
export function openServicePay(data) {
  return request({
    url: '/work/shop/open_server_manager',
    method: 'post',
    data,
  })
}
/**
 *
 *专属服务-微信支付结果
 */
export function wxpayResult(params) {
  return request({
    url: '/work/shop/pay_order',
    method: 'get',
    params,
  })
}

/**
 * 专属服务经理列表
 * */
export function getServiceManagerList(data) {
  return request({
    url: '/work/shop/serverManagerList',
    method: 'post',
    data,
  })
}

/**
 * 添加专属服务经理
 * */
export function addServiceManager(data) {
  return request({
    url: '/work/shop/addServerManager',
    method: 'post',
    data,
  })
}

/**
 * 删除专属服务经理
 * */
export function delServiceManager(data) {
  return request({
    url: '/work/shop/delServerManager',
    method: 'post',
    data,
  })
}

/**
 * 修改专属服务经理职位
 * */
export function editServiceManagerPosition(data) {
  return request({
    url: '/work/shop/setPosition',
    method: 'post',
    data,
  })
}

/**
 * 绑定移动工作台用户列表
 * */
export function getBindUserList(data) {
  return request({
    url: '/work/shop/getNotServerManager',
    method: 'post',
    data,
  })
}
