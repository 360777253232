<template>
  <div class="table-content" ref="tableContent">
    <div class="table-operation">
      <a-button type="primary" @click="serveceVidible = true">
        添加服务经理
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :data-source="list"
      rowKey="id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="employeeEdit(record)" style="margin-right: 20px">修改职位</a>
        <a @click="unbind(record)">解绑</a>
      </span>
      <template #avatar="avatar">
        <img
          style="width: 38px; height: 38px; border-radius: 50%"
          :src="avatar"
          alt="头像"
        />
      </template>
    </a-table>

    <!-- 修改密码 -->
    <a-modal
      dialog-class="modal-content"
      width="600px"
      title="修改职位"
      okText="确定"
      cancelText="取消"
      :visible.sync="employeeVisible"
      :mask-closable="false"
      @ok="employeeConfirm"
      @cancel="
        employeeVisible = false
        employeeForm = {}
        $refs.form.clearValidate()
      "
    >
      <a-form-model
        ref="form"
        :model="employeeForm"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-model-item
          label="职位"
          prop="position_id"
          :rules="{ required: true, message: '请选择职位' }"
        >
          <a-select v-model="employeeForm.position_id" placeholder="请选择职位">
            <a-select-option
              v-for="item in positionList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 点击添加服务经理 -->
    <a-modal v-model="serveceVidible" title="添加服务经理" width="680px">
      <template #footer>
        <a-button type="primary" @click="serviceConfirm">保存</a-button>
      </template>
      <a-table
        rowKey="id"
        :columns="serviceColumns"
        :row-selection="{
          selections: true,
          onChange: (selectedRowKeys, selectedRows) =>
            (this.selectedRows = selectedRows),
        }"
        :data-source="bindUserList"
        :pagination="servicePagination"
        @change="handleServiceTableChange"
      >
        <template slot="avatar" slot-scope="avatar">
          <img
            style="width: 38px; height: 38px; border-radius: 50%"
            :src="avatar"
            alt="头像"
          />
        </template>
      </a-table>
    </a-modal>
    <a-modal
      v-model="serviceVisible"
      width="1120px"
      :footer="null"
      :closable="false"
      :mask-closable="false"
      :keyboard="false"
      wrap-class-name="service-modal-warp"
      :get-container="() => $refs.tableContent"
      :maskStyle="{
        top: '66px',
        background: 'rgba(0, 0, 0, 0.2)',
        height: 'calc(100vh - 66px)',
      }"
      :dialogStyle="{ top: '100px' }"
      :bodyStyle="{ padding: '50px' }"
    >
      <div class="service-wrap">
        <div class="service-item">
          <img src="@/assets/service-1.png" alt="1" />
          <p class="service-item-title">专属服务名片</p>
          <p class="service-item-text">
            被设置为专属服务经理的员工，可修改自己的头像、姓名、手机号等信息，顾客查看邀请函可以直接联系到他的专属服务经理。
          </p>
        </div>
        <div class="service-item">
          <img src="@/assets/service-2.png" alt="1" />
          <p class="service-item-title">专属服务短信</p>
          <p class="service-item-text">
            专属服务经理为顾客订包间，顾客收到的短信和邀请函，带有专属服务经理的电话。
          </p>
        </div>
        <div class="service-item">
          <img src="@/assets/service-3.png" alt="1" />
          <p class="service-item-title">专属获客动态</p>
          <p class="service-item-text">
            顾客转发餐厅名片或者邀请函给同行好友，开通的服务经理能实时收到一条转发动态。
          </p>
        </div>
      </div>
      <div class="type_box mt-4">
        <div>
          <span class="text">付费价格：{{ needPrice }}元/年</span>
        </div>
        <div class="mt-2">
          <span class="text">付费方式：</span>
          <a-radio-group v-model="typePay">
            <a-radio
              :value="1"
              :disabled="Number(corp_money) < Number(needPrice)"
              >从备用金扣除 （店铺备用金{{ corp_money }}元）</a-radio
            >
            <a-radio :value="2">微信支付</a-radio>
          </a-radio-group>
        </div>
        <div class="divTips mt-2">
          注：开通专属服务经理后短信模板将切换至尊享版短信，且不可再切换为低版本短信。
        </div>
      </div>
      <div class="flex justify-center mt-10">
        <a-button
          type="primary"
          :style="{ height: '42px', width: '320px' }"
          @click="openService"
        >
          付费并开通专属服务经理
        </a-button>
      </div>
    </a-modal>
    <!-- 微信支付 -->
    <a-modal
      width="680px"
      :visible.sync="qrCodeVisible"
      title="开通服务经理"
      @cancel="visibleCancel"
      @ok="visibleCancel"
    >
      <template>
        <div class="qr-code mx-auto">
          <a-spin :spinning="qrLoading">
            <img
              :src="qrCode || require('@/assets/qrcode-empty.png')"
              alt="二维码"
              class="w-full cursor-pointer"
              @click="getQRCode(1)"
            />
          </a-spin>
        </div>
        <div class="pay-tips mx-auto">
          <a-icon type="scan" style="font-size: 30px; margin-right: 8px" />
          <div>请使用微信扫一扫 扫描二维码支付</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getPositionList } from '@/api/position'
import _ from 'lodash'
import {
  getpay,
  openServicePay,
  wxpayResult,
  getServiceManagerList,
  editServiceManagerPosition,
  delServiceManager,
  getBindUserList,
  addServiceManager,
  getServiceStatus,
} from '@/api/dedicatedServiceManager'
import storage from 'store'
import { setMessageTemplate, getTopupList } from '@/api/moneyManage'
import { USER_INFO } from '@/utils/request'
export default {
  name: 'ServiceManagerList',
  data() {
    return {
      serviceVisible: false,
      timer: null,
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
        },
        {
          title: '头像',
          dataIndex: 'mini_user.avatar',
          scopedSlots: { customRender: 'avatar' },
        },
        {
          title: '昵称',
          dataIndex: 'mini_user.nickname',
        },
        {
          title: '职位',
          dataIndex: 'with_position.name',
          customRender: (name) => name || '暂无职位',
        },
        {
          title: '手机号',
          dataIndex: 'mini_user.phone',
          customRender: (name) => name || '无',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      positionList: [],
      employeeForm: {
        position_id: undefined,
      },
      employeeVisible: false,
      serveceVidible: false,
      list: [],
      pagination: {
        current: 1,
        defaultPageSize: 15,
        showTotal: (total) => `共${total}条`,
      },
      serviceColumns: [
        {
          title: '昵称',
          dataIndex: 'nickname',
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
        },
      ],
      bindUserList: [],
      servicePagination: {
        current: 1,
        defaultPageSize: 15,
        showTotal: (total) => `共${total}条`,
      },
      selectedRows: [],
      needPrice: 0,
      corp_money: 0, // 备用金
      typePay: '',
      qrCodeVisible: false,
      qrLoading: false,
      qrCode: '',
    }
  },
  mounted() {
    const userInfo = storage.get(USER_INFO)

    if (userInfo && !userInfo.shop.corpid) {
      this.getServiceStatus()
    } else {
      this.getList()
      this.getPositionList()
      this.getBindUserList()
    }
  },
  methods: {
    getServiceStatus() {
      getServiceStatus().then((res) => {
        if (res.code === 0) {
          this.serviceVisible = !res.data.is_open
          if (res.data.is_open) {
            this.getList()
            this.getPositionList()
            this.getBindUserList()
          }
          if (this.serviceVisible) {
            this.getPayFun()
            this.getCorpMoneyFun()
          }
        }
      })
    },
    getPayFun() {
      getpay().then((result) => {
        if (result.code === 0) {
          this.needPrice = (result.data.price / 100).toFixed(2)
        }
      })
    },
    // 获取店铺备用金
    getCorpMoneyFun() {
      getTopupList({
        order_room_shop_id: this.$store.state.user.info.shop.id,
        luyou_shop_id: 0,
      }).then((result) => {
        if (result.code === 0) {
          this.corp_money = (result.data.corp_money / 100).toFixed(2)
        }
      })
    },
    // 微信支付-二维码
    visibleCancel() {
      this.qrCodeVisible = false
      this.qrLoading = false
    },
    getPositionList() {
      getPositionList().then((res) => {
        if (res.code === 0) {
          this.positionList = res.data
        }
      })
    },

    // 获取绑定人员
    getList(page = 1) {
      clearInterval(this.timer)
      this.$store.dispatch('loading/startContentLoading')
      getServiceManagerList({ page }).then((res) => {
        if (res.code === 0) {
          const pagination = { ...this.pagination }
          pagination.total = res.data.total
          pagination.pageSize = res.data.per_page
          this.list = res.data.data
          this.pagination = pagination
        }
        this.$store.dispatch('loading/endContentLoading')
      })
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getList(pagination.current)
    },
    handleServiceTableChange(pagination) {
      const pager = { ...this.servicePagination }
      pager.current = pagination.current
      this.servicePagination = pager
      this.getBindUserList(pagination.current)
    },
    employeeEdit(record) {
      this.employeeForm = _.cloneDeep(record)
      if (!this.employeeForm.with_position)
        this.employeeForm.position_id = undefined
      this.employeeVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    employeeConfirm() {
      this.$refs.form
        .validate()
        .then(() => {
          this.$message.loading({ content: '保存中', key: 'setPosition' })
          editServiceManagerPosition({
            openid: this.employeeForm.openid,
            position_id: this.employeeForm.position_id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success({
                content: '保存成功',
                key: 'setPosition',
              })
              this.employeeVisible = false
              this.getList()
            }
          })
        })
        .catch((err) => err)
    },
    unbind(record) {
      this.$confirm({
        title: '提示',
        content: '您确定解绑该服务经理吗？',
        onOk: () => {
          delServiceManager({ openid: record.openid })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success('解绑成功！')
                this.getList()
              }
            })
            .finally(() => {
              getBindUserList()
            })
        },
      })
    },

    // 获取未绑定员工列表
    getBindUserList(page = 1) {
      getBindUserList({ page }).then((res) => {
        if (res.code === 0) {
          const servicePagination = { ...this.servicePagination }
          servicePagination.total = res.data.total
          servicePagination.pageSize = res.data.per_page
          this.bindUserList = res.data.data
          this.servicePagination = servicePagination
        }
      })
    },

    serviceConfirm() {
      this.$message.loading({ content: '保存中', key: 'save' })
      addServiceManager({
        openid: this.selectedRows.map((item) => item.openid),
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success({ content: '保存成功！', key: 'save' })
        };

        this.serveceVidible = false;
        this.getList();
      })
    },
    // 开通经理服务
    openService() {
      if (this.typePay && this.typePay / 1 === 2) {
        this.wxPayFun()
        // setServiceStatus().then((res) => {
        //   if (res.code === 0) {
        //     this.$message.success("开通成功！");
        //     this.serviceVisible = false;
        //     this.getList();
        //   } else {
        //     this.$message.destroy();
        //     this.$confirm({
        //       title: "提示",
        //       content:
        //         "开通专属服务经理需要短信模板切换至“尊享预订回执”，您是否要把短信模板切换至“尊享预订回执”，并开通专属服务经理功能。",
        //       onOk: () => {
        //         this.openZxMsg();
        //         // this.$router.push({ name: "MoneyManage" });
        //       },
        //     });
        //   }
        // });
      } else if (this.typePay && this.typePay / 1 === 1) {
        this.byjPayFun()
      } else {
        this.$message.warning('请选择付费方式~')
      }
    },
    byjPayFun() {
      this.$confirm({
        title: '提示',
        content: '开通专属服务经理扣除备用金' + this.needPrice + '元。',
        onOk: () => {
          const data = {
            type: this.typePay,
          }
          openServicePay(data).then((res) => {
            if (res.code === 0) {
              this.$message
                .success({
                  text: '开通成功~',
                  duration: '400',
                })
                .then((res) => {
                  window.location.reload()
                })
            }
          })
        },
      })
    },
    wxPayFun() {
      this.qrCodeVisible = true
      const data = {
        type: this.typePay,
      }
      openServicePay(data).then((res) => {
        if (res.code === 0) {
          this.qrCode = res.data.qrcode;
          this.loadwxPayFun(res.data.order_id)
        }
      })
    },
    loadwxPayFun(val) {
      this.timer = setInterval(() => {
        const data = {
          order_id: val,
        };
        
        wxpayResult(data).then((res) => {
          if (res.code === 0 && res.data.status === 1) {
            this.$message
              .success({
                text: '开通成功~',
                duration: '400',
              })
              .then((res) => {
                window.location.reload()
              })
          }
        })
      }, 4000)
    },
    openZxMsg() {
      setMessageTemplate({ template_type: 3 }).then((res) => {
        if (res.code === 0) {
          this.openService()
        }
      })
    },
  },
  destroyed() {
    clearInterval(this.timer)
  },
}
</script>

<style scoped lang="less">
::v-deep .service-modal-warp {
  top: 66px;
}
.service-wrap {
  display: flex;
  align-items: center;
}
.service-item {
  width: 320px;
  height: 478px;
  margin-right: 30px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  padding: 30px 10px;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.service-item-title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  margin-top: 43px;
  margin-bottom: 12px;
}

.service-item-text {
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  line-height: 20px;
}
.divTips {
  font-size: 14px;
  color: #e63e30;
}

.qr-code {
  width: 260px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
  }

  .qr-code-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pay-tips {
  margin-top: 6px;
  width: 260px;
  height: 50px;
  font-size: 12px;
  color: #e63e30;
  line-height: 18px;
  background: #fff4f3;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
}
</style>
